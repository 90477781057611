import React from "react";
import Main from "../components/Main";

const MainPage = () => {
	return (
		<>
			<Main />
		</>
	);
};

export default MainPage;
